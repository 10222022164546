import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss' ]
})
export class OtpComponent {
  @Output() submitResendOTP = new EventEmitter<any>();

  @Output() submitEmailOTP = new EventEmitter<any>();

  otpName: string;
  otpDescription: string;
  showOtpError = false;
  storeVariables: any;

  constructor(
    public dialogRef: MatDialogRef<OtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
  ) {
    
    if (localStorage.getItem('state')) {
      this.storeVariables = JSON.parse(localStorage.getItem('state'))
    }

    switch (data.OtpType) {
      case "Sign Document":
        {
          this.otpName = 'Sign Document OTP'
          this.otpDescription = 'I hereby agree to the terms and conditions of the preagreement via this otp verification'
          break;
        }

        case "Consent to credit check":
          {
            this.otpName = 'Credit Check OTP'
            this.otpDescription = 'I hereby authorise LNDR (Pty) to conduct a full credit check on my profile in order to conduct a complete financial analysis of my affairs , for the purpose of providing me with credit related services, i hereby consent to the financial institution sharing such analysis with other credit providers'
            break;
          }

          default: 
          this.otpName = 'Login OTP'
          this.otpDescription = 'We have sent a one-time password (OTP) to your registered phone number. Please enter the OTP to complete your login.'
          break;
    };

    // switch (data.OtpType) {
    //   case "Login":
    //     {
    //       this.otpName = 'Login OTP'
    //       this.otpDescription = 'This OTP is part of our Two Factor Authentication Security Policy, please enter the OTP we sent to the mobile number registered to this account.'
    //       break;
    //     }
    //   case "Register":
    //     {
    //       this.otpName = 'Registration OTP'
    //       this.otpDescription = 'This OTP is part of our Two Factor Authentication Security Policy, please enter the OTP we sent to the mobile number registered to your new account.'
    //       break;
    //     }
    //   case "Reset Password":
    //     {
    //       this.otpName = 'Reset Password OTP'
    //       this.otpDescription = 'This OTP is part of our Two Factor Authentication Security Policy, please enter the OTP we sent to the mobile number registered to your new account.'
    //       break;
    //     }
    //   case "Sign Document":
    //     {
    //       this.otpName = 'Sign Document OTP'
    //       if (this.user.role == "2") {
    //         this.otpDescription = 'I hereby agree to the terms and conditions of the deed of adherence via this OTP verification'
    //       } else {
    //         this.otpDescription = 'Please ask the client to provide the OTP in order to digitally sign the document'
    //       }
    //       break;
    //     }
    //   case "Sign Document - CIC":
    //     {
    //       this.otpName = 'Sign Document OTP'
    //       if (this.user.role == "2") {
    //         this.otpDescription = 'I hereby agree to the terms and conditions of the Client Investment Consent via this OTP verification'
    //       } else {
    //         this.otpDescription = 'To complete the digital signature process, please input the OTP provided to the client via their registered cell phone number'
    //       }
    //       break;
    //     }
    //   case "Consent":
    //     {
    //       this.otpName = 'Consent OTP'
    //       this.otpDescription = 'This OTP is to authorise Aluma Capital to retrieve and process information from third parties. '
    //       break;
    //     }
    //   default: {
    //     this.otpName = 'Two Factor Authentication'
    //     break;
    //   }
    // }
    this.showOtpError = data.InvalidOtp;
  }

  resendOTP() {
    this.submitResendOTP.emit();
    this.openSnackBar(
      "Your OTP has been resent",
      "success-snackbar"
    );
  }

  emailOTP() {
    this.submitEmailOTP.emit();
    this.openSnackBar(
      "Your OTP has been emailed to you",
      "success-snackbar"
    );
  }


  openSnackBar(msg: string, panel: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "bottom",
    });
  }





}