<div class="container-fluid">
    <div class="form-position">
        <form [formGroup]="personalDetails" novalidate>
            <div class="card-body col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Identification Type:</label>
                            </div>
                            <div class="col-md-4">
                                <mat-radio-group class="example-radio-group" labelPosition="after">
                                    <mat-radio-button *ngFor="let item of radio_list" checked="{{item.checked}}" name="{{item.name}}" value="{{item.value}}" disabled="{{item.disabled}}" (click)="radioSelect(item.value)">
                                        {{item.label}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">{{idTypeValue == 0 ? 'RSA ID Number' : 'Passport Number'}}
                            </mat-label>
                            <input matInput class="mat-textbox" input name="Identification" input type="text" formControlName="idNumber" autocomplete="nope">
                            <mat-error>
                                Please enter a valid ID number.
                            </mat-error>
                            <mat-icon matSuffix *ngIf="idTypeValue==0"><i class="fa fa-id-card"></i></mat-icon>
                            <mat-icon matSuffix *ngIf="idTypeValue==1"><i class="fa fa-passport"></i></mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="idTypeValue==1">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Country of Issue</mat-label>
                            <mat-select formControlName="country">
                                <mat-form-field appearance="fill">
                                    <mat-icon matPrefix><i class="fa fa-search"></i></mat-icon>
                                    <input style="font-size: 14;" matInput matInput (keyup)="onKey($event.target.value)">
                                </mat-form-field>
                                <mat-option *ngFor="let country of selectedCountries" [value]="country.id">
                                    {{country.countryName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="idTypeValue==1">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Date of Birth</mat-label>
                            <!-- <input matInput [matDatepicker]="picker1" formControlName="dateOfBirth" [(ngModel)]="ngDateOfBirth">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker> -->

                            <input matInput [matDatepicker]="picker1" [(ngModel)]="dateModel.ngDateOfBirth" formControlName="dateOfBirth">
                            <mat-datepicker-toggle matSuffix [for]="picker1">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>

                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="idTypeValue==1">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Date of Issue</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dateOfIssue" [(ngModel)]="dateModel.ngDateOfIssue">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="idTypeValue==1">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Expiration Date</mat-label>
                            <input matInput [matDatepicker]="picker3" formControlName="expiryDate" [(ngModel)]="dateModel.ngExpiryDate">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Name</mat-label>
                            <input matInput class="mat-textbox" input name="name" input type="text" formControlName="name" autocomplete="nope">
                            <mat-error>
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Surname</mat-label>
                            <input matInput class="mat-textbox" input name="surname" input type="text" formControlName="surname" autocomplete="nope">
                            <mat-error>
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Email</mat-label>
                            <input matInput class="mat-textbox" input name="email" input type="text" formControlName="email" autocomplete="nope">
                            <mat-error>
                                Please enter a valid email address.
                            </mat-error>
                            <mat-icon matSuffix><i class="fa fa-envelope"></i></mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Mobile Number</mat-label>
                            <input matInput class="mat-textbox" input name="mobile" input type="text" formControlName="mobileNumber" autocomplete="nope">
                            <mat-error>
                                Please enter a valid mobile number.
                            </mat-error>
                            <mat-icon matSuffix><i class="fa fa-phone"></i></mat-icon>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-6" *ngIf="!EditProfile">
                        <mat-form-field appearance="standard">
                            <mat-label class="mat-label-margin">Loan Amount</mat-label>
                            <input matInput class="mat-textbox" input name="name" min="500" input type="number" formControlName="loanamount" autocomplete="nope">
                            <mat-error>
                                Please enter a loan amount between R500 - R120 000
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6" *ngIf="!EditProfile">
                        <mat-label class="mat-label-margin">Loan Term (Months): {{ personalDetails.get('loanterm').value }}</mat-label>
                        <mat-slider
                        formControlName="loanterm"
                        thumbLabel
                        [displayWith]="formatLabel"
                        [min]="minmumTerm"
                        [max]="maxmumTerm"
                        class="mat-slider-green"
                        ></mat-slider>
                    </div> -->
                    <br><br><br>
                    <div class="col-md-6" *ngIf="!EditProfile">
                        <mat-checkbox formControlName="popiaConsent" labelPosition="before">Consent to 3rd party payment</mat-checkbox>
                    </div>
                    <div class="col-md-3" *ngIf="!EditProfile">
                        <mat-checkbox formControlName="marketingConsent" labelPosition="before">Consent to Marketing</mat-checkbox>
                    </div>
                    <div class="col-md-3" *ngIf="!EditProfile">
                        <mat-checkbox formControlName="creditCheckConsent" (change)="creditCheckConsent($event)" labelPosition="before">Consent to credit check</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row row-padding">
                <br>
                <!-- <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="back()"><i class="fa fa-arrow-left"></i></button> -->
                <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!personalDetails.valid"><i class="fa fa-arrow-right" ></i></button>
            </div>
        </form>
    </div>
</div>
